import axios from 'axios';
import {useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useIsGetCodeFromParams, useSocket} from '../../providers/AvailabilityProviders/AvailabilityProviders';

import ConfirmModal from '../../shared/components/modals/confirmModal/ConfirmModal';
import useModal from '../../shared/hooks/useModal';
import {InputForm} from './components/inputForm/InputForm.styles';
import RequestFormFieldHeader from './components/requestFormFieldHeader/RequestFormFieldHeader';
import SubmitButton from './components/submitButton/SubmitButton';
import SwitchOptionButton from './components/switchOptionButton/SwitchOptionButton';
import ToneOptionButton from './components/toneOptionButton/ToneOptionButton';
import {
  ErrorMessage,
  RequestFormFieldContainer,
  RequestFormFieldWrapper,
  StyledDuetIcon,
  StyledErrorMessageFirstNameWrapper,
  StyledErrorMessageLastNameWrapper,
  StyledInputForm,
  StyledMelodyIcon,
  StyledToneIcon,
} from './RequestFormField.styles';
import {IFormRequest} from './RequestFormField.types';
import {setSong} from "../../redux/features/SongsSlice";
import {useAppDispatch} from '../../shared/hooks/useRedux';

const RequestFormField = ({song, code}: any) => {
  // const [song, setSong] = useState<any>([]);
  const [isLoadingQuery, setIsLoadingQuery] = useState<boolean>(false);
  const [statusRoom, setStatusRoom] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { visible, handleShowModal, handleHideModal } = useModal();

  const availabilityState = useIsGetCodeFromParams();
  const { socket } = useSocket();

  const {
    visible: visibleSuccessModal,
    handleShowModal: visibleSuccessHandleShowModal,
    handleHideModal: visibleSuccessHideModal,
  } = useModal();

  const onClose = () => {
    dispatch(setSong(null));
  }

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm<IFormRequest>();

  useEffect(() => {
    if (!statusRoom || !code) return;

    availabilityState.handleCodeFromURLParams(code);
    availabilityState.handleStatusRoom(statusRoom);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusRoom, code]);

  useEffect(() => {
    if (!code) return;

    const getStatusRoom = async () => {
      try {
        await axios.get(
          `https:/${process.env.REACT_APP_REQUEST_API_URL}/v2/pro/requests/status/${code}?client_id=${process.env.REACT_APP_REQUEST_CLIENT_ID}`,
        );
        setStatusRoom(true);
      } catch (error) {
        setStatusRoom(false);
        handleShowModal();
      }
    };

    getStatusRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (!errors.lastName?.message) return;
    clearErrors('lastName');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, watch('songDuet')]);

  const onSubmit: SubmitHandler<IFormRequest> = async (data: any) => {
    try {
      const requestData = {
        code,
        song_id: Number(song.id),
        names: data.songDuet
          ? JSON.stringify([data.firstName, data.lastName])
          : JSON.stringify([data.firstName]),
        song_melody_line: data.songMelodyLine ? 1 : 0,
        song_key: Number(data.songKey),
        song_duet: data.songDuet ? 1 : 0,
      };

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      setIsLoadingQuery(true);

      await axios.post(
        `https://${process.env.REACT_APP_REQUEST_API_URL}/v2/pro/requests?client_id=${process.env.REACT_APP_REQUEST_CLIENT_ID}`,
        requestData,
        config,
      );

      if(window && typeof window.gtag !== 'undefined') {
        window.gtag('event', 'song_request', {
          event_category: 'requests',
          event_label: 'song',
          value: song.id
        });
      }

      setIsLoadingQuery(false);

      if (statusRoom && socket) {
        socket.emit('updateRequests', code);
      }

      visibleSuccessHandleShowModal();
    } catch (error: any) {
      setIsLoadingQuery(false);
      handleShowModal();
    }
  };

  if(!song){
    return <></>;
  }

  return (
    <RequestFormFieldContainer>
      <ConfirmModal
        confetti={true}
        visible={visible}
        onClose={handleHideModal}
        onPositiveAction={() => {
          dispatch(setSong(null));
        }}
        titleModal="Przyjmowanie zgłoszeń jest niedostępne"
        descriptionModal="Prowadzący zamknął możliwość wysyłania zgłoszeń."
      />
      <ConfirmModal
        confetti={true}
        visible={visibleSuccessModal}
        onClose={visibleSuccessHideModal}
        onPositiveAction={() => {
          dispatch(setSong(null));
        }}
        titleModal="Twoje zgłoszenie zostało wysłane 🎉"
        descriptionModal="Prowadzący otrzymał Twoje zgłoszenie. Przygotuj się do swojego występu."
      />
      <RequestFormFieldWrapper onSubmit={handleSubmit(onSubmit)}>
        <RequestFormFieldHeader song={song} onClose={onClose} />
        <Controller
          rules={{
            required: {
              value: true,
              message: 'To pole powinno zostać uzupełnione',
            },
          }}
          render={({ field: { onChange } }) => {
            return (
              <InputForm
                isError={errors?.firstName?.message}
                onChange={(event: any) => onChange(event.target.value)}
                placeholder="Wpisz swoje imię"
              />
            );
          }}
          control={control}
          name="firstName"
        />
        {errors?.firstName?.message && (
          <StyledErrorMessageFirstNameWrapper>
            <ErrorMessage>{errors?.firstName?.message}</ErrorMessage>
          </StyledErrorMessageFirstNameWrapper>
        )}
        {watch('songDuet') && (
          <Controller
            rules={{
              required: {
                value: true,
                message: 'To pole powinno zostać uzupełnione',
              },
            }}
            render={({ field: { onChange } }) => {
              return (
                <StyledInputForm
                  isError={errors.lastName?.message}
                  onChange={(event: any) => onChange(event.target.value)}
                  placeholder="Wpisz imię drugiej osoby"
                />
              );
            }}
            control={control}
            name="lastName"
          />
        )}
        {getValues('songDuet') && errors?.lastName?.message && (
          <StyledErrorMessageLastNameWrapper>
            <ErrorMessage>{errors?.lastName?.message}</ErrorMessage>
          </StyledErrorMessageLastNameWrapper>
        )}
        <Controller
          defaultValue={false}
          render={({ field: { value, onChange } }) => {
            return (
              <SwitchOptionButton
                isFirstSwitchButton
                value={value}
                onChange={onChange}
                titleOption="Duet z drugą osobą"
                icon={<StyledDuetIcon />}
              />
            );
          }}
          control={control}
          name="songDuet"
        />
        <Controller
          defaultValue={false}
          render={({ field: { value, onChange } }) => {
            return (
              <SwitchOptionButton
                value={value}
                onChange={onChange}
                titleOption="Linia melodyczna"
                icon={<StyledMelodyIcon />}
              />
            );
          }}
          control={control}
          name="songMelodyLine"
        />
        <Controller
          defaultValue={0}
          render={({ field: { value, onChange } }) => {
            return (
              <ToneOptionButton
                value={value}
                onChange={onChange}
                titleOption="Tonacja"
                icon={<StyledToneIcon />}
              />
            );
          }}
          control={control}
          name="songKey"
        />
        <SubmitButton isLoading={isLoadingQuery}>
          Wyślij zgłoszenie
        </SubmitButton>
      </RequestFormFieldWrapper>
    </RequestFormFieldContainer>
  );
};

export default RequestFormField;
