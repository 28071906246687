import SwitchButtonWithText from '../../../listSongsPage/components/SwitchButtonWithText/SwitchButtonWithText';
import {IconBox, IconWithLabel, SwitchOptionButtonWrapper, SwitchOptionTitle} from './SwitchOptionButton.styles';
import {ISwitchOptionButton} from './SwitchOptionButton.types';

const SwitchOptionButton = ({ titleOption, onChange, value, icon, isFirstSwitchButton }: ISwitchOptionButton) => {
    return (
        <SwitchOptionButtonWrapper isFirstSwitchButton={isFirstSwitchButton}>
            <IconWithLabel>
                <IconBox>
                {icon}
                </IconBox>
                <SwitchOptionTitle>{titleOption}</SwitchOptionTitle>
            </IconWithLabel>
            <SwitchButtonWithText onChange={onChange} value={value} />
        </SwitchOptionButtonWrapper>
    );
};

export default SwitchOptionButton;