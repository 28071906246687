import {createContext} from 'react';

import {useLocalStorage} from '../../shared/hooks/useLocalStorage';
import {IContextProps, IMultiThemeProviderProps,} from './MultiThemeProvider.types';

export const ThemeContext = createContext<IContextProps>({
  theme: '',
  setTheme: () => null,
});

export const MultiThemeProvider = ({ children }: IMultiThemeProviderProps) => {
  const [theme, setTheme] = useLocalStorage('theme', 'lightTheme');

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
