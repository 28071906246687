import {Controller} from 'react-hook-form';

import Dropdown from '../Dropdown/Dropdown';
import SearchInput from '../SearchInput/SearchInput';
import SortBar from '../SortBar/SortBar';
import {ListSongsTopBarWrapper, SearchBarContainer,} from './ListSongsTopBar.styles';

const ListSongsTopBar = ({ form, setIsInputFocused, isInputFocused }: any) => {
  const { control, watch } = form;

  return (
    <ListSongsTopBarWrapper>
      <SearchBarContainer>
        <Controller
          render={({ field: { value, onChange } }) => {
            return (
              <SearchInput
                setFilterValueByAuthorOrSongName={onChange}
                setIsInputFocused={setIsInputFocused}
              />
            );
          }}
          control={control}
          name="filterValueByAuthorOrSongName"
        />
        <Controller
          defaultValue="Wszystkie"
          render={({ field: { value, onChange } }) => {
            return (
              <Dropdown
                typeMusic={value}
                setTypeMusic={onChange}
                isInputFocused={
                  watch('filterValueByAuthorOrSongName')
                    ? true
                    : false || isInputFocused
                }
              />
            );
          }}
          control={control}
          name="typeMusic"
        />
      </SearchBarContainer>
      <Controller
        defaultValue="A-Z"
        render={({ field: { value, onChange } }) => {
          return (
            <SortBar
              sortLetter={value}
              setSortLetter={onChange}
              isInputFocused={
                watch('filterValueByAuthorOrSongName')
                  ? true
                  : false || isInputFocused
              }
            />
          );
        }}
        control={control}
        name="sortLetter"
      />
    </ListSongsTopBarWrapper>
  );
};

export default ListSongsTopBar;
