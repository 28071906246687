import axios from 'axios';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import {searchMoreSongsThunk, searchSongsThunk} from '../../redux/thunks/SongsThunk';

import ConfirmModal from '../../shared/components/modals/confirmModal/ConfirmModal';
import {routes} from '../../shared/constants/routePaths';
import useModal from '../../shared/hooks/useModal';
import {useAppDispatch, useAppSelector} from '../../shared/hooks/useRedux';
import ListSongsPreview from './components/listSong/ListSongsPreview';
import ListSongsTopBar from './components/listSongTopBar/ListSongsTopBar';
import {
  ListSongsContainer,
  ListSongsPageWrapper,
  LoaderAndInformationForUserContainer,
  LoaderBox,
  MessageNoSearchValue,
  StyledSearchInput,
  StyledSearchWithMessageContainer,
} from './ListSongsPage.styles';
import RequestFormField from "../requestsFormField/RequestFormField";

const ListSongs = () => {
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const listHeightRef = useRef<HTMLDivElement | null>(null);
  const loader = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isInfinityLoading, setIsInfinityLoading] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [statusRoom, setStatusRoom] = useState<boolean>(false);
  const {code = ''} = useParams();

  const navigate = useNavigate();
  const form = useForm<any>({
    defaultValues: {
      sortLetter: 'A-Z',
      typeMusic: 'Wszystkie',
      filterValueByAuthorOrSongName: '',
    },
  });

  const dispatch = useAppDispatch();

  const currentSong = useAppSelector(
    (store) => store.songs.song,
  );

  const searchSongsStatus = useAppSelector(
    (store) => store.songs.searchSongsStatus,
  );

  const listSongs = useAppSelector(
    (store) => store.songs.listSongs,
  );

  const linkTonNextData = useAppSelector((store) => store.songs.linkTonNextData);

  const searchMoreSongsStatus = useAppSelector((store) => store.songs.searchMoreSongsStatus);

  const {watch, getValues} = form;

  const sortLetter = watch('sortLetter');
  const typeMusic = watch('typeMusic');
  const filterValueByAuthorOrSongName = watch('filterValueByAuthorOrSongName');

  const {visible, handleShowModal, handleHideModal} = useModal();

  const saveScrollPosition = () => {
    if (listContainerRef.current) {
      setScrollPosition(listContainerRef.current.scrollTop);
    }
  };

  const loadMore = () => {
    if (!linkTonNextData || searchSongsStatus === 'loading') return;

    setIsInfinityLoading(true);

    saveScrollPosition();

    dispatch(searchMoreSongsThunk({linkTonNextData}));
  };

  const handleObserver = useCallback(
    (entries: any) => {
      const target = entries[0];

      if (target.isIntersecting) {
        loadMore();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [linkTonNextData, searchSongsStatus],
  );

  useEffect(() => {
    if (!code) return;

    const getStatusRoom = async () => {
      try {
        await axios.get(
          `https://${process.env.REACT_APP_REQUEST_API_URL}/v2/pro/requests/status/${code}?client_id=${process.env.REACT_APP_REQUEST_CLIENT_ID}`,
        );
        setStatusRoom(true);
      } catch (error) {
        setStatusRoom(false);
        handleShowModal();
      }
    };

    getStatusRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    dispatch(searchSongsThunk({filterValueByAuthorOrSongName, sortLetter, typeMusic}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortLetter, typeMusic, filterValueByAuthorOrSongName]);

  useEffect(() => {
    const listHeight = listHeightRef.current?.clientHeight || 0;
    const listContainerHeight = listContainerRef.current?.clientHeight || 0;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const currentLoaderRef = loader.current;

    const observer = new IntersectionObserver(handleObserver, options);

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };

    setIsInfinityLoading(false);
  }, [isInputFocused, loader, handleObserver]);

  useEffect(() => {
    if (isInfinityLoading) {
      if (listContainerRef.current) {
        listContainerRef.current.scrollTop = scrollPosition;
      }
      setIsInfinityLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSongs]);

  return (
    <ListSongsPageWrapper>
      {currentSong && <RequestFormField song={currentSong} code={code}/>}
      <ConfirmModal
        visible={visible}
        onClose={handleHideModal}
        onPositiveAction={() => {
          navigate(routes.listOfSongs);
        }}
        titleModal="Przyjmowanie zgłoszeń jest niedostępne"
        descriptionModal="Prowadzący zamknął możliwość wysyłania zgłoszeń."
      />
      <ListSongsTopBar
        form={form}
        isInputFocused={isInputFocused}
        setIsInputFocused={setIsInputFocused}
      />
      {searchSongsStatus === 'loading' ? (
        <LoaderAndInformationForUserContainer>
          <LoaderBox>
            {!filterValueByAuthorOrSongName && listSongs === null && (
              <ClipLoader color="#96989A" loading={searchSongsStatus} size={24}/>)}
          </LoaderBox>
        </LoaderAndInformationForUserContainer>
      ) : listSongs?.length === 0 &&
      filterValueByAuthorOrSongName ? (
        <LoaderAndInformationForUserContainer>
          <StyledSearchWithMessageContainer>
            <StyledSearchInput/>
            <MessageNoSearchValue>
              {`Nie znaleziono piosenek dla "${getValues(
                'filterValueByAuthorOrSongName',
              )}"`}
              <br/>
              Sprawdź pisownię lub spróbuj znaleźć coś innego.
            </MessageNoSearchValue>
          </StyledSearchWithMessageContainer>
        </LoaderAndInformationForUserContainer>
      ) : (
        listSongs?.length === 0 && !filterValueByAuthorOrSongName && !isInputFocused && (
          <LoaderAndInformationForUserContainer>
            <StyledSearchWithMessageContainer>
              <MessageNoSearchValue>
                Nie znaleziono takich piosenek
              </MessageNoSearchValue>
            </StyledSearchWithMessageContainer>
          </LoaderAndInformationForUserContainer>
        )
      )}
      {
        (((searchSongsStatus !== 'loading' && filterValueByAuthorOrSongName && isInputFocused) || (searchSongsStatus !== 'loading' && !isInputFocused))) && listSongs?.length !== 0 && (
          <ListSongsContainer ref={listContainerRef}>
            <ListSongsPreview
              statusRoom={statusRoom}
              listSongs={listSongs}
              loadMoreItemFromNextLink={loadMore}
              loader={loader}
              listHeightRef={listHeightRef}
              getMoreSongStatus={searchMoreSongsStatus === 'loading'}
            />
          </ListSongsContainer>
        )
      }
    </ListSongsPageWrapper>
  );
};

export default ListSongs;
