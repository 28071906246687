import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import io, {Socket} from 'socket.io-client';

interface SocketContextType {
    socket: Socket | null;
}

interface SocketDispatch {
  (codeValue: string): void;
}

interface StatusDispatch {
  (isStatusRoom: boolean): void;
}

type DispatchContextType = {
    handleCodeFromURLParams: SocketDispatch;
    handleStatusRoom: StatusDispatch;
  };

const SocketContext = createContext<SocketContextType>({ socket: null });

const SocketDispatchContext = createContext<DispatchContextType>({
  handleCodeFromURLParams: () => {},
  handleStatusRoom: () => {},
});

export const AvailabilityProvider = ({ children }: { children: ReactNode }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [statusRoom, setStatusRoom] = useState<boolean>(false);
    const [code, setIsCode] = useState<string>('');

    const handleCodeFromURLParams = (codeValue: string) => setIsCode(codeValue);
    const handleStatusRoom = (isStatusRoom: boolean) => setStatusRoom(isStatusRoom);

    useEffect(() => {
        if (!statusRoom) return;

        const url = process.env.REACT_APP_SOCKET_CLIENT as string;
        const connectionSocket = io(url, {
          auth: {
            token: code,
          },
        });

        setSocket(connectionSocket);

        return () => {
            socket?.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [statusRoom]);

  return (
    <SocketContext.Provider value={{ socket }}>
      <SocketDispatchContext.Provider value={{ handleCodeFromURLParams, handleStatusRoom }}>
        {children}
      </SocketDispatchContext.Provider>
    </SocketContext.Provider>
  );
};

export const useIsGetCodeFromParams = () => useContext(SocketDispatchContext);

export const useSocket = () => useContext(SocketContext);
