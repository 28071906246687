import ToneOptionDropdownButton from "../toneOptionDropdownButton/ToneOptionDropdownButton";
import {IconBox, IconWithLabel, ToneOptionButtonWrapper, ToneOptionTitle} from "./ToneOptionButton.styles";
import {IToneOptionButton} from "./ToneOptionButton.types";

const ToneOptionButton = ({ titleOption, onChange, value, icon }: IToneOptionButton) => {
    return (
        <ToneOptionButtonWrapper>
            <IconWithLabel>
                <IconBox>
                {icon}
                </IconBox>
                <ToneOptionTitle>{titleOption}</ToneOptionTitle>
            </IconWithLabel>
            <ToneOptionDropdownButton isInputFocused={false} currentTone={value} setCurrentTone={onChange} />
        </ToneOptionButtonWrapper>
    );
};

export default ToneOptionButton;