import styled from 'styled-components';
import SearchIcon from '../../shared/assets/icons/SearchIcon';

export const ListSongsPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #212224;
`;

export const ListSongsContainer = styled.div`
    padding: 15px 8px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
`;

export const DropdownContainer = styled.div<{ isInputFocused: boolean }>`
    display: ${({ isInputFocused }) => isInputFocused ? 'none' : 'flex'};
    align-items: center;
    margin-left: 10px;
`;

export const LoaderAndInformationForUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
`;

export const LoaderBox = styled.div``;

export const MessageNoSearchValue = styled.span`
    color: ${({ theme }) => theme.color.descriptionNoSearchValueTextColor};
    line-height: 21px;
    font-size: 13px;
`;

export const StyledSearchInput = styled(SearchIcon)`
    width: 60px;
    height: 60px;
    fill: ${({ theme }) => theme.color.whiteColor};
    margin-bottom: 10px;

    & path {
    fill: white;
  }
`;

export const StyledSearchWithMessageContainer = styled(LoaderBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StaticSearchBarAndSortBarContainer = styled.div``;
