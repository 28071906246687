import styled from 'styled-components';
import {MEDIA_QUERIES} from '../../../constants/mediaQueries';

export const ConfirmModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${MEDIA_QUERIES.md} {
    min-width: 644px;
  }
`;

export const ConfirmModalContent = styled.div`
  max-width: 800px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.secondaryTextColor};
`;

// export const DeleteConfirmModalButton = styled(Button)`
//   width: 100%;
//   margin-right: 16px;
//   background-color: ${({ theme }) => theme.color.white};
//   border: 1px solid ${({ theme }) => theme.color.primaryDivider};
//   color: ${({ theme }) => theme.color.primaryText};
// `;

// export const PositiveActionConfirmModalButton = styled(Button)`
//   width: 100%;
//   background-color: ${({ theme }) => theme.color.deleteButtonBackgroundColor};
//   color: ${({ theme }) => theme.color.white};
//   margin-bottom: 16px;

//   :hover {
//     color: ${({ theme }) => theme.color.white};
//   }

//   ${MEDIA_QUERIES.sm} {
//     margin-bottom: 0;
//   }
// `;

export const ModalViewTitle = styled.span`
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const ModalViewButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  align-items: flex-end;

  ${MEDIA_QUERIES.sm} {
    flex-direction: row;
  }

  ${MEDIA_QUERIES.md} {
      margin-left: auto;
      padding-top: 24px;
    }
`;

export const CircleLoaderWrapper = styled.div``;

export const StyledButtonAccept = styled.button`
    border-radius: 3px;
    width: 100%;
    height: 54px;
    box-shadow: inset 0 1px 0 0 #659aed;
    background-image: linear-gradient(to bottom, #2678f9, #0d6bff);
    color: ${({ theme }) => theme.color.whiteColor};
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border: none;

    ${MEDIA_QUERIES.md} {
      min-width: 120px;
    }
`;
