import {useEffect, useRef, useState} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import {AddOption, LoaderElement, StyledSubmitButton, SubmitButtonWrapper,} from './SubmitButton.styles';
import {SubmitButtonProps} from './SubmitButton.types';

const SubmitButton = ({
  isLoading,
  children,
  width: submitWidth,
  isAddOption,
  isEditButton,
  onClick,
  disabled,
}: SubmitButtonProps) => {
  const [buttonDim, setButtonDim] = useState({ width: 0, height: 0 });
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const { width, height } =
      buttonRef.current?.getBoundingClientRect() as DOMRect;
    setButtonDim({ width, height });
  }, [setButtonDim]);

  return (
    <SubmitButtonWrapper submitWidth={submitWidth} onClick={onClick}>
      <StyledSubmitButton
        ref={buttonRef}
        isEditButton={isEditButton}
        disabled={disabled}
      >
        {isAddOption && <AddOption>+</AddOption>}
        {!isLoading && children}
      </StyledSubmitButton>
      {isLoading && (
        <LoaderElement buttonDim={buttonDim}>
          <ClipLoader color="#EEE" loading={isLoading} size={24} />
        </LoaderElement>
      )}
    </SubmitButtonWrapper>
  );
};

export default SubmitButton;
