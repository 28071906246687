import Modal from '../../modal/Modal';
import {IModalProps} from '../../modal/Modal.types';
import {
  ConfirmModalContent,
  ConfirmModalWrapper,
  ModalViewButtonsContainer,
  ModalViewTitle,
  StyledButtonAccept,
} from './ConfirmModal.styles';
import React from "react";

function randomInRange(min: any, max: any) {
  return Math.random() * (max - min) + min;
}

const ConfirmModal = ({
                        visible,
                        onClose,
                        titleModal,
                        confetti,
                        descriptionModal,
                        onPositiveAction,
                      }: IModalProps) => {


  const BurstConfetti = () => {
    if (window.hasOwnProperty('confetti')) {
      window.confetti({
        scalar: 1.15,
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: {y: 0.6},
      });
    }

    return <></>;
  }

  return (
    <Modal visible={visible} onClose={onClose}>
      <ConfirmModalWrapper>
        <ModalViewTitle>{titleModal}</ModalViewTitle>
        <ConfirmModalContent>{descriptionModal}</ConfirmModalContent>
        {confetti && <BurstConfetti/>}
        <ModalViewButtonsContainer>
          <StyledButtonAccept onClick={() => {
            onPositiveAction();
            onClose();
          }}
          >
            OK
          </StyledButtonAccept>
        </ModalViewButtonsContainer>
      </ConfirmModalWrapper>
    </Modal>
  );
};

export default ConfirmModal;
