import {createGlobalStyle} from 'styled-components';
import {normalize} from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    html {
      height: 100%;
      scroll-behavior: smooth;
      min-height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    *, *::before, *::after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    textarea:focus, input:focus{
      outline: none;
    }

    body {
      font-family: 'Open Sans', sans-serif;
      background-color: #2e2f32;
      color: white;
      font-style: normal;
      overflow: hidden;
      min-height: 100vh;
      
      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        border-radius: 10px;
      }
      
      ::-webkit-scrollbar-thumb {
        background: lightgrey;
        border-radius: 10px;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  `;

export default GlobalStyle;
