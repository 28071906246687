import {useEffect} from 'react';

import {ScrollToTopWrapper} from './ScrollToTop.styles';
import {ScrollToTopProps} from './ScrollToTop.types';

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return <ScrollToTopWrapper>{children}</ScrollToTopWrapper>;
};

export default ScrollToTop;
