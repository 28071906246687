import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {store} from '../redux/store';
import {MultiThemeProvider} from './MultiThemeProvider/MultiThemeProvider';
import {ThemeProvider} from './ThemeProvider/ThemeProvider';
import ContextProviders from './ContextProviders/ContextProviders';
import RouterProvider from './RouterProvider';
import React from "react";

const Providers = () => (
  <Provider store={store}>
    <ContextProviders>
      <ToastContainer />
      <MultiThemeProvider>
        <ThemeProvider>
            <RouterProvider />
        </ThemeProvider>
      </MultiThemeProvider>
    </ContextProviders>
  </Provider>
);
export default Providers;
