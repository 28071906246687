import styled from 'styled-components';
import CloseIcon from '../../../../shared/assets/icons/CloseIcon';
import SearchIcon from '../../../../shared/assets/icons/SearchIcon';

import {MEDIA_QUERIES} from '../../../../shared/constants/mediaQueries';
import {SortBarWrapper} from '../SortBar/SortBar.styles';

export const StyledSortBarWrapper = styled(SortBarWrapper)``;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: calc((100% - 16px) / 2);
  left: 14px;
  width: 16px;
  height: 16px;
  color: #d9d9d9;

  ${MEDIA_QUERIES.lg} {
    top: calc((100% - 16px) / 2);
    left: 18px;
    width: 16px;
    height: 16px;
  }

  ${MEDIA_QUERIES.xl} {
    top: calc((100% - 18px) / 2);
    left: 22px;
    width: 18px;
    height: 18px;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: calc((100% - 12px) / 2);
  right: 14px;
  width: 12px;
  height: 12px;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
`;

export const SearchInputForSong = styled.input<any>`
  width: 100%;
  background: ${({ theme }) => theme.color.searchInputBackgroundColor};
  border-radius: 4px;
  border: none;
  padding: 9px 10px 9px 38px;
  height: 36px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.whiteColor};
  background-image: linear-gradient(to bottom, #434446, #414243);

  &::placeholder {
    color: ${({ theme }) => theme.color.secondaryTextColor};
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    background-color: blue;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.color.searchInputFocusShadowColor};
    color: ${({ theme }) => theme.color.whiteColor};
  }

  ${MEDIA_QUERIES.lg} {
    height: 42px;
    padding: 9px 10px 9px 44px;
  }

  ${MEDIA_QUERIES.xl} {
    height: 48px;
    padding: 9px 10px 9px 52px;
  }

  ${SortBarWrapper} {
      display: none;
   }
`;

export const SearchInputWrapper = styled.div`
    width: 100%;
    position: relative;
    /* height: 48px; */

    ${SearchInputForSong}:placeholder-shown + svg {
    path {
      color: ${({ theme }) => theme.color.secondaryTextColor};
    }
    }

    ${SearchInputForSong}:focus + svg {
    path {
        fill: ${({ theme }) => theme.color.white};
    }
    }

    path {
    fill: ${({ theme }) => theme.color.white};
    }
`;

export const CloseIconBox = styled.div``;
