import styled from 'styled-components';
import {MEDIA_QUERIES} from '../../../../shared/constants/mediaQueries';

export const SwitchOptionButtonWrapper = styled.div<{ isFirstSwitchButton: boolean | undefined }>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${({ isFirstSwitchButton }) => isFirstSwitchButton ? '46px 0 26px 0' : '26px 0'};
    border-bottom: 1px solid ${({ theme }) => theme.color.lineInFormSectionColor};

    ${MEDIA_QUERIES.xsMobile} {
        align-items: center;
    }
`;

export const SwitchOptionTitle = styled.span`
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 16px;
`;

export const IconWithLabel = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   overflow: hidden;
   margin-right: 22px;
`;

export const IconBox = styled.div`
    width: 24px;
    height: 24px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const LeftSpan = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const RightDiv = styled.div`
  flex: 1;
`;