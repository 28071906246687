import {ThemeProvider as Provider} from 'styled-components';

import {useContext} from 'react';

import {THEMES_TYPE} from '../../shared/constants/themes';
import {darkTheme, lightTheme} from '../../shared/theme/theme';
import {ThemeContext} from '../MultiThemeProvider/MultiThemeProvider';
import {IThemeProviderProps} from './ThemeProvider.types';

export const ThemeProvider = ({ children }: IThemeProviderProps) => {
  const { theme } = useContext(ThemeContext);

  let selectedTheme = null;

  if (theme === THEMES_TYPE.lightTheme) {
    selectedTheme = lightTheme;
  } else if (theme === THEMES_TYPE.darkTheme) {
    selectedTheme = darkTheme;
  } else {
    console.log('error theme types');
  }

  return <Provider theme={selectedTheme}>{children}</Provider>;
};
