import styled from 'styled-components';

import {MEDIA_QUERIES} from '../../constants/mediaQueries';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.color.modalOverlayBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow: auto;
`;

export const ModalView = styled.div`
  display: block;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.modalViewBackgroundColor};
  padding: 40px 20px 30px;
  transition: all 300ms ease-out;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 0;

  ${MEDIA_QUERIES.md} {
    display: flex;
    width: auto;
    height: auto;
  }
`;

export const ModalViewHeader = styled.div`
  margin-left: auto;
`;

// export const StyledCloseModalButton = styled(TimesIcon)`
//   font-size: 24px;
//   font-weight: bold;
//   cursor: pointer;
// `;

export const TransitionContainer = styled.div`
  .modal-enter {
    opacity: 0.01;

    .modal-view {
      transform: scale(0.9) translateY(20%);
    }
  }
  .modal-enter-active {
    opacity: 1;
    transition: all 300ms ease-out;

    .modal-view {
      transform: scale(1) translateY(0%);
    }
  }
  .modal-exit {
    opacity: 1;

    .modal-view {
      transform: scale(1) translateY(0%);
    }
  }
  .modal-exit-active {
    opacity: 0.01;
    transition: all 300ms ease-out;

    .modal-view {
      transform: scale(0.9) translateY(20%);
    }
  }
`;
