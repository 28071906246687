import styled from 'styled-components';

import {MEDIA_QUERIES} from '../../../../shared/constants/mediaQueries';
import {ISwitchLabelProps} from './SwitchButtonWithText.types';

export const SwitchLabel = styled.label<ISwitchLabelProps>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  border-radius: 5px;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.color.inputBackgroundColor};
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: ${({ value }) => value ? '0 5px 5px 0' : '5px 0 0 5px'};
    width: 50%;
    height: 40px;
    background-color: #0d6bff;
    transition: 0.2s;
    color: ${({ theme }) => theme.color.whiteColor};

    :first-child {
      
    }
  }

  ${MEDIA_QUERIES.md} {
    width: 128px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 128px;
  min-width: 128px;
  height: 40px;

  ${MEDIA_QUERIES.xsMobile} {
    margin-left: auto;
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 40px;
  cursor: pointer;
  &:checked + ${SwitchLabel} {
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      margin-left: 50%;
      transition: 0.2s;
    }
  }

  ${MEDIA_QUERIES.md} {
    width: 128px;
  }
`;

export const SwitchText = styled.span<any>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  position: absolute;
  width: 50%;
  text-align: center;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  :first-child {
    left: 0;
    color: ${({ theme, isToggled }) =>
      !isToggled ? theme.color.whiteText : theme.color.primaryText};
  }

  :last-child {
    right: 0;
    color: ${({ theme, isToggled }) =>
      isToggled ? theme.color.whiteText : theme.color.primaryText};
  }

  ${MEDIA_QUERIES.md} {
    width: 64px;
  }
`;