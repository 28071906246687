import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const songRequestThunk = createAsyncThunk(
  'songs/request',
  async (data: any, {rejectWithValue}) => {
    try {
      return data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error);
    }
  }
)

export const searchSongsThunk = createAsyncThunk(
  'songs/searchSongs',
  async ({filterValueByAuthorOrSongName, sortLetter, typeMusic}: any, {rejectWithValue}) => {
    try {
      let tag = '';
      let q = '';

      if (!filterValueByAuthorOrSongName) {
        if (typeMusic === 'Polskie') {
          tag = 'polskie';
        } else if (typeMusic === 'Zagraniczne') {
          tag = 'muzyka+zagraniczna';
        }
      }

      const params = {
        client_id: `${process.env.REACT_APP_SEARCH_LIST_SONG_CLIENT_ID}`,
        q:
          filterValueByAuthorOrSongName ||
          (sortLetter !== 'A-Z' ? sortLetter : ''),
        tag: tag,
        order: '-artist_string',
        scope: 'songs',
        per_page: 50,
        facet: filterValueByAuthorOrSongName
          ? ''
          : sortLetter !== 'A-Z'
            ? 'artist_letter'
            : '',
        field: filterValueByAuthorOrSongName
          ? ''
          : sortLetter !== 'A-Z'
            ? 'artist_letter'
            : '',
      };

      const response = await axios.get(
        `https://${process.env.REACT_APP_REQUEST_API_URL}/v2/search`,
        {
          params: params
        },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const searchMoreSongsThunk = createAsyncThunk(
  'songs/searchMoreSongs',
  async ({linkTonNextData}: any, {rejectWithValue}) => {
    try {
      const response = await axios.get(linkTonNextData);

      return response.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error);
    }
  },
);