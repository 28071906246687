import styled from 'styled-components';

export const DropdownContainer = styled.div< { isInputFocused: boolean }>`
  position: relative;
  display: ${({ isInputFocused }) => isInputFocused ? 'none' : 'flex'};
  align-items: center;
  margin-left: auto;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    width: 12px;
    height: 6px;
    border-top: 6px dashed #000;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-style: solid;
    border-color: white transparent transparent transparent;
    transform: translateY(-50%);
  }
`;

export const DropdownWrapper = styled.select`
    min-width: 128px;
    max-width: 128px;
    height: 40px;
    padding: 9px 6px 9px 14px;
    background-color: ${({ theme }) => theme.color.inputBackgroundColor};
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.color.whiteColor};
    white-space: nowrap;
    font-size: 15px;
    font-weight: 600;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    :focus {
        outline: none;
    }
`;
export const DropdownOption = styled.option``;