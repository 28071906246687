import styled from 'styled-components';
import {MEDIA_QUERIES} from '../../../../shared/constants/mediaQueries';
import {ISortBarElementProps} from './SortBar.types';

export const SortBarWrapper = styled.div< { isInputFocused: boolean }>`
    display: ${({ isInputFocused }) => isInputFocused ? 'none' : 'flex'};
    min-height: 53px;
    padding: 5px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.color.borderLineColor};
    overflow-x: auto;
    justify-content: space-between;
`;

export const SortBarElement = styled.button<ISortBarElementProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '600' : '400')};
  text-transform: uppercase;
  white-space: nowrap;
  border: none;
  flex: 1 1 auto;
  border-radius: 6px;
  line-height: 2;
  min-width: 38px;
  background-color: ${({ theme, active }) => (active ? theme.color.activeSortElementBackgroundColor : 'transparent')};
  color: ${({ active, theme }) => (active ? theme.color.whiteColor : theme.color.secondaryTextColor)};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
 
  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.color.activeSortElementBackgroundColor : theme.color.hoverSortElementBackgroundColor)};
  }

  :first-child {
      min-width: 52px;
    }

  :nth-child(2) {
    min-width: 52px;
  }

  ${MEDIA_QUERIES.md} {
    font-size: 15px;
    min-width: 52px;
  }
`;