import {SongAuthor, SongAvatar, SongElementWrapper, SongTitle, SongTitleWrapper} from './SongElement.styles';
import {ISongElementProps} from './SongElement.types';
import React from "react";

const SongElement = ({ onClick, song, code }: ISongElementProps) => {
    const { title, artist, images, colors } = song;

    return (
        <SongElementWrapper onClick={onClick} code={code}>
            <SongAvatar src={images.cover_80} alt={""} style={{backgroundColor: `#${colors?.cover.hex}`}} loading="lazy" />
            <SongTitleWrapper>
              <SongTitle>{title}</SongTitle>
              <SongAuthor>
                  {`– ${artist}`}
              </SongAuthor>
            </SongTitleWrapper>
        </SongElementWrapper>
    );
};

export default SongElement;