import styled from 'styled-components';

export const ListSongsPreviewWrapper = styled.div``;

export const LoadingDataButton = styled.div<{ isVisible: boolean }>`
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};
  text-align: center;
  background-color: #646568;
  border-color: #646568;
  border-top-color: #8b8b8b;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
  opacity: 0;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
`;