import {SwitchInput, SwitchLabel, SwitchText, SwitchWrapper,} from './SwitchButtonWithText.styles';

export const SwitchButtonWithText = ({
  id,
  toggled,
  onChange,
  value,
}: any) => {
  return (
    <SwitchWrapper>
        {toggled !== null && (
          <SwitchInput
            id={id}
            type="checkbox"
            checked={toggled}
            onChange={onChange}
          />
        )}
        <SwitchLabel value={value} htmlFor={id}>
          <SwitchText isToggled={toggled}>Nie</SwitchText>
          <SwitchText isToggled={toggled}>
            Tak
          </SwitchText>
        </SwitchLabel>
    </SwitchWrapper>
  );
};

export default SwitchButtonWithText;