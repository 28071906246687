import styled from 'styled-components';
import {MEDIA_QUERIES} from '../../../../shared/constants/mediaQueries';

export const ToneOptionButtonWrapper = styled.div`
    display: flex;
    padding: 26px 0;

    :first-child {
        margin-top: 46px;
        padding-bottom: 26px;
    }

    ${MEDIA_QUERIES.xsMobile} {
        align-items: center;
    }
`;

export const ToneOptionTitle = styled.span`
    margin-left: 16px;
`;

export const IconWithLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const IconBox = styled.div`
    width: 24px;
    height: 24px;
`;