import styled from 'styled-components';
import CloseIcon from '../../../../shared/assets/icons/CloseIcon';

export const RequestFormFieldHeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 40px;
    position: relative;
`;

export const RequestFormFieldSongAvatar = styled.img`
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    background-size: cover;
    border-radius: 6px;
    background-color: silver;
`;

export const TitleSongWithAuthorContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    overflow: hidden;
`;

export const TitleSong = styled.span`
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 22px;
    color: ${({ theme }) => theme.color.whiteColor};
    margin-right: 16px;
`;

export const AuthorSong = styled.span`
    color: ${({ theme }) => theme.color.secondaryTextColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 16px;
`;

export const CloseIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -8px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  transition: opacity 0.25s;
  fill: ${({ theme }) => theme.color.closeIconFormColor};

  &:hover {
    opacity: 1;
  }
`;

export const LoaderBox = styled.div`
     display: flex;
     margin: auto;
     height: 56px;
`;
