import styled, {css} from 'styled-components';

export const SongTitle = styled.span`
    font-weight: 600;
    margin-right: 5px;
    color: ${({ theme }) => theme.color.whiteColor};
`;

export const SongTitleWrapper = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline;
    line-height: 20px;
    color: ${({ theme }) => theme.color.secondaryTextColor};
`;

export const SongElementWrapper = styled.div<{ code: string }>`
    display: flex;
    width: 100%;
    padding: 8px;
    font-size: 13px;
    white-space: nowrap;
    align-items: center;
    position: relative;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    user-select: none;

    &:after{
      background: ${({ theme }) => theme.color.borderLineColor};
      height: 1px;
      position: absolute;
      left: 8px;
      right: 8px;
      content: "";
      bottom: -1px;
    }
  
    :last-of-type {
        &:after{
          display: none;
        }
    }

    ${({ code }) =>
    code &&
    css`
       :hover {
        cursor: pointer;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.color.hoverListSongElement};
    }
    `}
`;

export const SongAvatar = styled.img`
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    background-size: cover;
    border-radius: 4px;
    margin-right: 10px;
    background-color: silver;
`;

export const SongAuthor = styled.span`
    
`;
