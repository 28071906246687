import {lettersSortBar} from '../../../../shared/constants/constants';
import {SortBarElement, SortBarWrapper} from './SortBar.styles';

const SortBar = ({ isInputFocused, sortLetter, setSortLetter }: { isInputFocused: boolean, sortLetter: string, setSortLetter: any }) => {
  return (
   <SortBarWrapper isInputFocused={isInputFocused}>
    <SortBarElement
      active={sortLetter === 'A-Z'}
      onClick={() => setSortLetter('A-Z')}
    >
        A–Z
    </SortBarElement>
    <SortBarElement
      active={sortLetter === '0'}
      onClick={() => setSortLetter('0')}
    >
        0–9
    </SortBarElement>
      {lettersSortBar.map((letter) => (
        <SortBarElement
          key={letter}
          active={sortLetter === letter}
          onClick={() => setSortLetter(letter)}
        >
          {letter}
        </SortBarElement>
      ))}
   </SortBarWrapper>
  );
};

export default SortBar;