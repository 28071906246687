import styled, {css} from 'styled-components';

export const InputForm = styled.input<{ isError: string | undefined }>`
    height: 50px;
    padding: 12px 15px 14px 15px;
    background-color: ${({ theme }) => theme.color.inputBackgroundColor};
    font-size: 18px;
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.color.whiteColor};

    ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${({ theme }) => theme.color.requiredInputErrorMessage};
    `}

    &::placeholder {
    color:  ${({ theme }) => theme.color.inputPlaceholderColor};
  }
`;