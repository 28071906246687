import {useNavigate, useParams} from 'react-router';
import SongElement from '../SongElement/SongElement';
import {ListSongsPreviewWrapper, LoadingDataButton,} from './ListSongsPreview.styles';
import {IListSongsPreviewProps} from './ListSongsPreview.types';
import {useAppDispatch} from "../../../../shared/hooks/useRedux";
import {setSong} from '../../../../redux/features/SongsSlice';
import React from "react";

const ListSongsPreview = ({
  listSongs,
  loadMoreItemFromNextLink,
  loader,
  getMoreSongStatus,
  statusRoom,
  listHeightRef,
}: IListSongsPreviewProps) => {
  const { code = '' } = useParams();

  const dispatch = useAppDispatch();

  const handleNavigateToRequestSendForm = (songId: number) => {
    if (!code && !statusRoom) return;

    const song = listSongs.find(
      (currentSong: any) => currentSong.id === songId,
    );

    dispatch(setSong(song));
  };

  return (
    <ListSongsPreviewWrapper ref={listHeightRef}>
      <div>
        {listSongs !== null &&
          listSongs.map((song: any) => (
            <SongElement
              key={song.id}
              song={song}
              onClick={() => {
                handleNavigateToRequestSendForm(song.id);
              }}
              code={code}
            />
          ))}
      </div>
      <LoadingDataButton
        onClick={loadMoreItemFromNextLink}
        ref={loader}
        isVisible={getMoreSongStatus}
      >
        Zobacz więcej
      </LoadingDataButton>
    </ListSongsPreviewWrapper>
  );
};

export default ListSongsPreview;
