import styled from 'styled-components';
import {ButtonSendRequest} from '../../RequestFormField.styles';

import {LoaderElementProps, SubmitButtonProps, SubmitWrapperProps,} from './SubmitButton.types';

export const SubmitButtonWrapper = styled.div<SubmitWrapperProps>`
  user-select: none;
  width: 100%;
  margin-top: auto;
  position: relative;
  display: flex;
`;

export const LoaderElement = styled.div<LoaderElementProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ buttonDim }) => `${buttonDim.width}px`};
  height: ${({ buttonDim }) => `${buttonDim.height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledSubmitButton = styled(ButtonSendRequest)<SubmitButtonProps>`
  width: 100%;
  height: 53px;
`;

export const AddOption = styled.div`
  margin-right: 8px;
`;
