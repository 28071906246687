import {DropdownContainer, DropdownOption, DropdownWrapper} from './Dropdown.styles';

const Dropdown = ({ isInputFocused, typeMusic, setTypeMusic }: { isInputFocused: boolean, typeMusic: string, setTypeMusic: any }) => {
    return (
        <DropdownContainer isInputFocused={isInputFocused}>
            <DropdownWrapper
              value={typeMusic}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setTypeMusic(event.target.value);
            }}
            >
                <DropdownOption value="Polskie">Polskie</DropdownOption>
                <DropdownOption value="Zagraniczne">Zagraniczne</DropdownOption>
                <DropdownOption value="Wszystkie">Wszystkie</DropdownOption>
            </DropdownWrapper>
        </DropdownContainer>
    );
};

export default Dropdown;