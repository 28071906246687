// import { initializeApp } from 'firebase/app';
import Providers from './providers';

// const firebaseConfig = {
//     apiKey: 'AIzaSyAHvQlPKT0G4GVn6RYu2cMELABzXlisDt4',
//     authDomain: 'ising-pro-form.firebaseapp.com',
//     projectId: 'ising-pro-form',
//     storageBucket: 'ising-pro-form.appspot.com',
//     messagingSenderId: '242322141314',
//     appId: '1:242322141314:web:45904792a71ff4c36e049b',
//   };

// const app = initializeApp(firebaseConfig);

const App = () => <Providers />;

export default App;
