import {createSlice} from '@reduxjs/toolkit';
import {searchMoreSongsThunk, searchSongsThunk} from '../thunks/SongsThunk';

const initialState = {
  song: null,
  listSongs: null,
  searchSongsStatus: null,
  searchMoreSongsStatus: null,
  linkTonNextData: null,
} as any;

export const SongsSlice = createSlice({
  name: 'songs',
  initialState,
  reducers: {
    clearSongsList: (state) => {
      state.listSongs = [];
    },
    setSong: (state, data) => {
      state.song = data.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchSongsThunk.pending, (state) => {
      state.searchSongsStatus = 'loading';
    });
    builder.addCase(searchSongsThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.listSongs = payload.data.results.songs;

      if (payload?.links && payload?.links?.next) {
        state.linkTonNextData = payload.links.next;
      } else {
        state.linkTonNextData = null;
      }

      state.searchSongsStatus = 'success';
    });

    builder.addCase(searchMoreSongsThunk.rejected, (state) => {
      state.searchSongsStatus = 'failed';
    });

    builder.addCase(searchMoreSongsThunk.pending, (state) => {
      state.searchMoreSongsStatus = 'loading';
    });

    builder.addCase(searchMoreSongsThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.listSongs = [...state.listSongs, ...payload.data.results.songs];

      if (payload?.links) {
        state.linkTonNextData = payload.links.next;
      } else {
        state.linkTonNextData = null;
      }

      if (payload?.links && payload?.links?.next) {
        state.linkTonNextData = payload.links.next;
      } else {
        state.linkTonNextData = null;
      }

      state.searchMoreSongsStatus = 'success';
    });

    builder.addCase(searchSongsThunk.rejected, (state) => {
      state.searchMoreSongsStatus = 'failed';
    });
  },
});

export const { clearSongsList, setSong } = SongsSlice.actions;

export default SongsSlice.reducer;
