import {useState} from 'react';

const useModal = (initialVisible = false) => {
  const [visible, updateVisible] = useState(initialVisible);

  const handleShowModal = () => {
    updateVisible(true);
  };

  const handleHideModal = () => {
    updateVisible(false);
  };

  return {
    visible,
    handleShowModal,
    handleHideModal,
  };
};

export default useModal;
