const MEDIA_QUERIES: { [key: string]: string } = {
  us: '@media screen and (min-width: 280px)',
  xs: '@media screen and (min-width: 320px)',
  xsMobile: '@media screen and (min-width: 375px)',
  sm: '@media screen and (min-width: 576px)',
  md: '@media screen and (min-width: 767px)',
  wm: '@media screen and (min-width: 870px)',
  lg: '@media screen and (min-width: 991px)',
  xl: '@media screen and (min-width: 1200px)',
  xxl: '@media screen and (min-width: 1520px)',
};

export { MEDIA_QUERIES };
