import {CSSTransition} from 'react-transition-group';
import {createPortal} from 'react-dom';

import {ModalView, ModalViewHeader, ModalWrapper, TransitionContainer,} from './Modal.styles';
import {ModalProps} from './Modal.types';

const portalDiv = document.getElementById('modal');
if (!portalDiv) {
  throw new Error('Element #modal nie istnieje w drzewku DOM');
}

const Modal = ({ visible, onClose, children }: ModalProps) => {
  return createPortal(
    <TransitionContainer>
      <CSSTransition
        in={visible}
        timeout={400}
        unmountOnExit
        classNames="modal"
      >
        {(state) => (
          <ModalWrapper>
            <ModalView className="modal-view">
              <ModalViewHeader>
                {/* <StyledCloseModalButton onClick={onClose} /> */}
              </ModalViewHeader>
              {children}
            </ModalView>
          </ModalWrapper>
        )}
      </CSSTransition>
    </TransitionContainer>,
    portalDiv,
  );
};

export default Modal;
