import React from "react";
import {DropdownContainer, DropdownOption, DropdownWrapper} from './ToneOptionDropdownButton.styles';

const ToneOptionDropdownButton = ({ isInputFocused, currentTone, setCurrentTone }: { isInputFocused: boolean, currentTone: number, setCurrentTone: any }) => {
    return (
        <DropdownContainer isInputFocused={isInputFocused}>
            <DropdownWrapper
              value={currentTone}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setCurrentTone(event.target.value);
            }}
            >
                <DropdownOption value={-2}>-2</DropdownOption>
                <DropdownOption value={-1}>-1</DropdownOption>
                <DropdownOption value={0}>Oryginalna</DropdownOption>
                <DropdownOption value={1}>+1</DropdownOption>
                <DropdownOption value={2}>+2</DropdownOption>
            </DropdownWrapper>
        </DropdownContainer>
    );
};

export default ToneOptionDropdownButton;