import React, {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import RequestFormField from '../pages/requestsFormField/RequestFormField';
import ListSongsPage from '../pages/listSongsPage/ListSongsPage';
import {routes} from '../shared/constants/routePaths';
import TemplateDevice from '../templates/TemplateDevice';

const NavigationRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<TemplateDevice />}>
          <Route path={`${routes.listOfSongs}`} element={<ListSongsPage />} />
          <Route path="/:code" element={<ListSongsPage />} />
          {/*<Route*/}
          {/*  path={`${routes.requestSend}/:code`}*/}
          {/*  element={<RequestFormField />}*/}
          {/*/>*/}
          <Route
            path={routes.notFoundPage}
            element={<Navigate to={routes.listOfSongs} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default NavigationRoutes;
