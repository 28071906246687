import styled from 'styled-components';
import DuetIcon from '../../shared/assets/icons/DuetIcon';
import MelodyIcon from '../../shared/assets/icons/MelodyIcon';
import ToneIcon from '../../shared/assets/icons/ToneIcon';
import {InputForm} from './components/inputForm/InputForm.styles';

export const RequestFormFieldContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: #212224;
`;

export const RequestFormFieldWrapper = styled.form`
    display: flex;
    flex-direction: column;
    padding: 34px 20px 30px 20px;
    width: 100%;
    max-width: 600px;
    height: 100%;
    overflow: auto;
    flex: 1;
`;

export const ButtonSendRequest = styled.button`
    border-radius: 3px;
    height: 54px;
    box-shadow: inset 0 1px 0 0 #659aed;
    background-image: linear-gradient(to bottom, #2678f9, #0d6bff);
    color: ${({ theme }) => theme.color.whiteColor};
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border: none;
`;

export const StyledInputForm = styled(InputForm)`
    margin-top: 10px;
    width: 100%;
`;

export const StyledDuetIcon = styled(DuetIcon)``;

export const StyledMelodyIcon = styled(MelodyIcon)``;

export const StyledToneIcon = styled(ToneIcon)``;

export const ErrorMessageWrapper = styled.div`
  display: flex;
`;

export const StyledErrorMessageLastNameWrapper = styled(ErrorMessageWrapper)`
    margin-top: 10px;
`;

export const StyledErrorMessageFirstNameWrapper = styled(ErrorMessageWrapper)`
    margin-top: 10px;
`;

export const ErrorMessage = styled.span`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.color.requiredInputErrorMessage};
`;
