import {useNavigate, useParams} from 'react-router';

import {routes} from '../../../../shared/constants/routePaths';
import {
  AuthorSong,
  CloseIconBox,
  RequestFormFieldHeaderWrapper,
  RequestFormFieldSongAvatar,
  StyledCloseIcon,
  TitleSong,
  TitleSongWithAuthorContainer
} from './RequestFormFieldHeader.styles';
import {IRequestFormFieldHeader} from './RequestFormFieldHeader.types';
import React from "react";

const RequestFormFieldHeader = ({ song, onClose }: IRequestFormFieldHeader) => {
    const { artist, title, images, colors } = song;

    const { code = '' } = useParams();

    const navigate = useNavigate();

    const navigateToSongList = () => {
        if (code) {
            navigate(`/${code}`);
        } else {
            navigate(`${routes.listOfSongs}`);
        }
    };

    return (
        <RequestFormFieldHeaderWrapper>
                <RequestFormFieldSongAvatar src={images?.cover_80} alt={""} style={{backgroundColor: colors?.cover.hex}} />
                <TitleSongWithAuthorContainer>
                    <TitleSong>{title}</TitleSong>
                    <AuthorSong>{artist}</AuthorSong>
                </TitleSongWithAuthorContainer>
            <CloseIconBox onClick={onClose}>
                <StyledCloseIcon />
            </CloseIconBox>
        </RequestFormFieldHeaderWrapper>
    );
};

export default RequestFormFieldHeader;