import React from 'react';
import {Outlet} from 'react-router';

import {TemplateDeviceWrapper} from './scrollToTop/TemplateDevice.styles';

const TemplateDevice = () => {
    const appHeight = () => {
        document.documentElement.style.setProperty(
          '--app-height',
          `${window.innerHeight}px`,
        );
        window.addEventListener('resize', appHeight);
        window.addEventListener('orientationchange', appHeight);
      };
      appHeight();

    return (
        <TemplateDeviceWrapper>
            <Outlet />
        </TemplateDeviceWrapper>
    );
};

export default TemplateDevice;