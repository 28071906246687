import styled from 'styled-components';

export const ListSongsTopBarWrapper = styled.div`
    width: 100%;
`;

export const SearchBarContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.color.borderLineColor};
`;