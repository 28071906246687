import ReactDOM from 'react-dom/client';
import gsap from 'gsap';

import App from './App';
import reportWebVitals from './reportWebVitals';

gsap.config({
  nullTargetWarn: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

reportWebVitals();
