import {BrowserRouter} from 'react-router-dom';

import NavigationRoutes from '../../routes/NavigationRoutes';
import GlobalStyle from '../../shared/theme/GlobalStyle';
import ScrollToTop from '../../templates/scrollToTop/ScrollToTop';
import {AvailabilityProvider} from '../AvailabilityProviders/AvailabilityProviders';
import React from "react";

const RouterProvider = () => (
  <BrowserRouter>
  <AvailabilityProvider>
    <ScrollToTop>
      <GlobalStyle />
      <NavigationRoutes />
    </ScrollToTop>
  </AvailabilityProvider>
  </BrowserRouter>
);

export default RouterProvider;
