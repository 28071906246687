export const lightTheme = {
  color: {
    backgroundColorApplication: '#212224',
    hoverSortElementBackgroundColor: '#2E2F32',
    hoverListSongElement: '#313235',
    activeSortElementBackgroundColor: '#0d6bff',
    borderLineColor: '#2E2F32',
    inputBackgroundColor: 'rgba(255, 255, 255, 0.1)',
    inputPlaceholderColor: 'rgba(255, 255, 255, 0.5)',
    secondaryTextColor: '#A0A2A4',
    whiteColor: '#ffffff',
    searchInputBackgroundColor: '#434446',
    closeIconFormColor: '#d9d9d9',
    searchInputFocusShadowColor: 'rgba(13, 107, 255, 0.6)',
    lineInFormSectionColor: 'rgba(238, 238, 238, 0.1)',
    requiredInputErrorMessage: '#ff554e',
    descriptionNoSearchValueTextColor: '#787878',

    // modals
    modalViewBackgroundColor: '#2E2F32',
    modalOverlayBackgroundColor: 'rgba(0, 0, 0, 0.7)',
    modalViewDescriptionTextColor: '#7C8493',
  },
};

export const darkTheme = {
  color: {},
};
