import {useEffect, useRef, useState} from 'react';
import {clearSongsList} from '../../../../redux/features/SongsSlice';
import useDebounce from '../../../../shared/hooks/useDebounce';
import {useAppDispatch} from '../../../../shared/hooks/useRedux';
import {
  CloseIconBox,
  SearchInputForSong,
  SearchInputWrapper,
  StyledCloseIcon,
  StyledSearchIcon,
} from './SearchInput.styles';

const SearchInput = ({
  setIsInputFocused,
  setFilterValueByAuthorOrSongName,
}: {
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterValueByAuthorOrSongName: React.Dispatch<
    React.SetStateAction<string>
  >;
}) => {
  const [filterValue, setFilterValue] = useState<string>('');

  const debouncedFilterValue = useDebounce<string>(filterValue, 500);

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearClick = () => {
    if (inputRef.current) {
      setFilterValue('');
      inputRef.current.value = '';
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    dispatch(clearSongsList());
    setFilterValueByAuthorOrSongName(debouncedFilterValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValue]);

  return (
    <SearchInputWrapper>
      <SearchInputForSong
        ref={inputRef}
        placeholder="Szukaj piosenek lub wykonawców"
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        value={filterValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFilterValue(event.target.value);
        }}
      />
      <StyledSearchIcon />
      {debouncedFilterValue && (
        <CloseIconBox onClick={handleClearClick}>
          <StyledCloseIcon onClick={handleClearClick} />
        </CloseIconBox>
      )}
    </SearchInputWrapper>
  );
};

export default SearchInput;
